import React from "react";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";

// Styles
import "./index.css";
import "./skodel-services.css";

// Components
import IndexLayout from "../layouts";
import Page from "../components/Page";
import StackedImage from "../components/StackedImage";
import { RiskAssessmentForm } from "../components/RiskAssessmentForm";

// Images and icons
import HomeHero from "../assets/images/home-hero.jpg";
import SkodelCheckCircle from "../assets/images/skodel-check-circle.svg";
import SkodelStrategyCircleWhite from "../assets/images/skodel-strategy-circle-white.svg";
import SkodelStrategyCircleDark from "../assets/images/skodel-strategy-circle-dark.svg";
import SkodelChartCircle from "../assets/images/skodel-chart-circle.svg";
import SkodelCollectCircleWhite from "../assets/images/skodel-collect-circle-white.svg";
import SkodelCollectCircleDark from "../assets/images/skodel-collect-circle-dark.svg";
import SkodelClock from "../assets/images/skodel-clock.svg";
import SkodelClockDark from "../assets/images/skodel-clock-dark.svg";
import SkodelCreationCircle from "../assets/images/skodel-creation-circle.svg";
import SkodelConsultation from "../assets/images/skodel-consultation.svg";
import SkodelConsultationDark from "../assets/images/skodel-consultation-dark.svg";
import SkodelCreation from "../assets/images/skodel-creation.svg";
import SkodelCreationDark from "../assets/images/skodel-creation-dark.svg";
import SkodelConfig from "../assets/images/skodel-configuration.svg";
import SkodelConfigDark from "../assets/images/skodel-configuration-dark.svg";
import TestimonialBG from "../assets/images/testimonial-bg.png";
import TestimonialQuote from "../assets/images/testimonial-quote-bg.png";
import ServiceCardBG from "../assets/images/leading-card-bg.png";
import MartynCampbell from "../assets/images/martyn-campbell.png";
import ElizaDiniakos from "../assets/images/eliza-diniakos-2.png";
import RiskAssessment from "../assets/images/risk-assessment.png";

// @ts-ignore
const NavLink = (props: any) => <Link activeClassName="active" {...props} />;

const ServicesData = [
  {
    id: "SR0001",
    image: SkodelCheckCircle,
    title: "Affordable and transparent pricing",
    description: "",
  },
  {
    id: "SR0002",
    image: SkodelChartCircle,
    title: "Reduce ongoing costs and time to manage your risk assessment",
    description: "",
  },
  {
    id: "SR0003",
    image: SkodelCreationCircle,
    title: "Meet growing compliance with regulatory backing",
    description: "",
  },
];

const ProblemsWeSolveData = [
  {
    id: "SV0001",
    imageWhite: SkodelCollectCircleWhite,
    imageDark: SkodelCollectCircleDark,
    title: "Struggling to maintain compliance with growing regulations",
    description: "",
  },
  {
    id: "SV0002",
    imageWhite: SkodelClock,
    imageDark: SkodelClockDark,
    title:
      "Limited resources to consult with staff and update risk assessments",
    description: "",
  },
  {
    id: "SV0003",
    imageWhite: SkodelStrategyCircleWhite,
    imageDark: SkodelStrategyCircleDark,
    title: "Finding it difficult to get participation and trust from staff",
    description: "",
  },
];

const HowSkodelHelpsData = [
  {
    id: "SV0001",
    imageWhite: SkodelConsultation,
    imageDark: SkodelConsultationDark,
    title: "Simple consultation mechanism",
    description:
      "Simple way to consult with workers to identify hazards or review effectiveness. Make it a sustainable process for everyone.",
  },
  {
    id: "SV0002",
    imageWhite: SkodelCreation,
    imageDark: SkodelCreationDark,
    title: "Reduce false risk signals",
    description:
      "Reduce false signals where people who are doing fine state there is a risk as they were prompted to consider it.",
  },
  {
    id: "SV0003",
    imageWhite: SkodelConfig,
    imageDark: SkodelConfigDark,
    title: "Protection plan",
    description:
      "Clear guidance from experienced regulators on next steps to manage your risk assessment and risk register for compliance.",
  },
];

const META_TAGS = {
  title: "The quickest psychosocial hazard survey",
  description:
    "Avoid lengthy surveys that overwhelm everyone. Our check is regulator backed and aligned to Safe Work Australia’s Model Code of Practice.",
};

const PsychosocialSurvey = () => {
  return (
    <IndexLayout withBlackLogo={true} workplacesHeader={false}>
      <Helmet>
        {/*  Primary Meta Tags */}
        <title>{META_TAGS.title}</title>
        <meta name="title" content={META_TAGS.title} />
        <meta name="description" content={META_TAGS.description} />

        {/*  Open Graph / Facebook  */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skodel.com/education" />
        <meta property="og:title" content={META_TAGS.title} />
        <meta property="og:description" content={META_TAGS.description} />
        <meta property="og:image" content={HomeHero} />

        {/*  Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://skodel.com/education" />
        <meta property="twitter:title" content={META_TAGS.title} />
        <meta property="twitter:description" content={META_TAGS.description} />
        <meta property="twitter:image" content={HomeHero} />
      </Helmet>
      <Page>
        <section className="section-home-hero">
          <div
            className="section-bg"
            style={{ backgroundImage: `url('${HomeHero}')` }}
          ></div>
          <div className="main-container">
            <div className="home-hero-content">
              <h1>The quickest psychosocial hazard survey</h1>
              <p>
                Avoid lengthy surveys that overwhelm everyone. Our check is
                regulator backed and aligned to Safe Work Australia’s Model Code
                of Practice.
              </p>
              <div className="home-hero-form">
                <div className="home-hero-form-content">
                  <RiskAssessmentForm
                    title="Contact us to learn more"
                    id="Psychosocial Survey"
                    gtagProjectId={
                      process.env.GATSBY_GTAG_PSYCHOSOCIAL_SURVEY_ID
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="separator-300" />
        <section className="section-home-middle">
          <div className="main-container">
            <div className="home-middle-wrapper">
              <div className="home-organisation-wrapper">
                <div className="section-title text-center">
                  <h2>Save time on consultation</h2>
                  <p>
                    Traditional surveys ask about all 14 hazards, this can
                    create a bias by prompting people who are doing fine. Our
                    check covers all hazards in a targeted approach, reducing
                    false signals and resulting in less disruption for everyone.
                  </p>
                </div>
                <div className="home-report-content">
                  <div className="home-report-video">
                    <div className="video-wrapper">
                      <iframe
                        src="https://www.loom.com/embed/cc6343d0479e490c920dd00a4e6433b2?sid=efd4d3ed-cf2e-47b5-90c3-00cecd75bada"
                        frameBorder="0"
                        width="100%"
                        height="600"
                        allowFullScreen
                      />
                    </div>
                  </div>
                  <div className="service-value">
                    <div className="section-title text-center">
                      <h2>Problems We Solve</h2>
                      <p>
                        Skodel simplifies the process of complying with
                        psychosocial hazard legislation. Our approach reduces
                        the administrative time and costs associated with
                        meeting these requirements.
                      </p>
                    </div>
                    <div className="service-value-grid column-3">
                      {ProblemsWeSolveData?.map((item, index) => (
                        <div
                          className="service-card"
                          key={item?.id + "-" + index}
                        >
                          <img src={ServiceCardBG} alt={"Skodel"} />
                          <div className="service-icon">
                            <img src={item?.imageWhite} alt={"Skodel"} />
                            <img src={item?.imageDark} alt={"Skodel"} />
                          </div>
                          <div className="service-description">
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="service-value">
                    <div className="section-title text-center">
                      <h2>How Skodel Helps</h2>
                    </div>
                    <div className="service-value-grid column-3">
                      {HowSkodelHelpsData?.map((item, index) => (
                        <div
                          className="service-card"
                          key={item?.id + "-" + index}
                        >
                          <img src={ServiceCardBG} alt={"Skodel"} />
                          <div className="service-icon">
                            <img src={item?.imageWhite} alt={"Skodel"} />
                            <img src={item?.imageDark} alt={"Skodel"} />
                          </div>
                          <div className="service-description">
                            <h3>{item?.title}</h3>
                            <p>{item?.description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="stacked-content">
                    <StackedImage
                      image={MartynCampbell}
                      title="Regulatory alignment for peace of mind"
                      description={
                        "“While Skodel's approach aligns nicely with legislation, it is its effectiveness which is most important. As a former regulator, I've always advised people that the best way to avoid fines and penalties is with processes that work, not just processes that are compliant.”"
                      }
                      author="Martyn Campbell, Former CEO Safe Work South Australia"
                    />
                  </div>
                  <div className="home-report-services">
                    <div className="services-grid">
                      <div className="services-text">
                        <h3>Benefits of Choosing Skodel</h3>

                        <div className="services-accordion">
                          {ServicesData?.map((item) => (
                            <div className="accordion-item">
                              <div className="accordion-head">
                                <img src={item?.image} alt={item?.title} />
                                <h3>{item?.title}</h3>
                              </div>
                              <div className="accordion-body">
                                <p>{item?.description}</p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="services-image">
                        <img src={RiskAssessment} alt={"Skodel"} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section-home-testimonial">
          <img src={TestimonialBG} alt={"Skodel"} />
          <div className="main-container">
            <div className="testimonial-wrapper">
              <div className="testimonial-text">
                <img src={TestimonialQuote} alt={"Skodel"} />
                <h3>“Boosting team efficiency & compliance”</h3>
                <p>
                  Using Skodel is a genuinely beneficial process for our teams
                  and has streamlined our compliance with psychosocial hazards—a
                  task that would have taken us months and significantly more
                  budget to achieve takes less than a week.
                </p>
                <p>
                  <strong>
                    Eliza Diniakos, Head of Operations Corporate Edge
                  </strong>
                </p>
              </div>
              <div className="testimonial-image">
                <img src={ElizaDiniakos} alt={"Andrew Fuller"} />
              </div>
            </div>
          </div>
        </section>
        <section className="section-home-bottom">
          <div className="main-container">
            <div className="home-hero-content">
              <RiskAssessmentForm
                title="Contact us to learn more"
                id="Psychosocial Survey"
                gtagProjectId={process.env.GATSBY_GTAG_PSYCHOSOCIAL_SURVEY_ID}
              />
            </div>
          </div>
        </section>
      </Page>
    </IndexLayout>
  );
};

export default PsychosocialSurvey;
